*
{
    box-sizing: border-box;
}

body
{
    animation: color-change 30s infinite;

    background-color: #282c34;
}

input:focus
{
    outline: none;
}

.App
{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    min-height: 100vh;
}

.App-logo
{
    max-width: calc(100% - (20px * 2));
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 20px;
    /*height: 40vmin;*/

    pointer-events: none;
}

.App-header
{
    font-size: calc(10px + 2vmin);

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    max-height: 50vh;

    color: white;
}

.App-main
{
    padding: 2em;
    max-width: 600px;
    width: 100%;
}

.App-link
{
    color: #61dafb;
}

.input-error {
    font-size: 12px;
    color: #ff0000;
}

.form-inner {
    padding: 2em;

    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .1);

    display: flex;
    flex-direction: column;
    gap: 10px;
}

.form-item
{
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 600px;
    margin-right: auto;
    margin-left: auto;
}

.form-item__label
{
    font-size: .9em;
    font-weight: bold;

    text-align: center;
    text-transform: uppercase;
}

.form-item__input
{
    font-family: inherit;
    font-size: 1em;

    padding: .85em 1em;

    border: 1px solid #ccc;
    border-radius: 6px;
    display: flex;
}

.form-item__field
{
    font-family: inherit;
    font-size: 1em;

    flex-basis: 100%;

    margin: 0;
    padding: 0;

    border: none;
    background-color: transparent;
}

.form-item__button
{
    font-weight: bold;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    padding: 1em;

    cursor: pointer;
    text-transform: uppercase;

    color: #282c34;
    border: 1px solid #282c34;
    border-radius: 3px;
    background-color: transparent;
}

.form-item__button:focus,
.form-item__button:hover
{
    color: #fff;
    background-color: #282c34;
}


.form-item.form-item--inner-button .form-item__input
{
    display: flex;
}

.optional-urls {
    display: none;
}
.optional-urls.open {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.clipboard
{
    font-style: italic;

    display: flex;
    align-items: center;

    width: 100%;
    max-width: calc(400px - 2em);
    margin: 2em auto;
    padding: .85em 1em;

    border-radius: 6px;
    background-color: antiquewhite;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
}

.clipboard__button
{
    font-size: 1.2em;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    width: 2em;
    height: 2em;
    margin-left: auto;

    cursor: pointer;

    color: #282c34;
    border: 1px solid #282c34;
    border-radius: 3px;
    background-color: transparent;
}

.clipboard__button:focus,
.clipboard__button:hover
{
    color: #fff;
    background-color: #282c34;
}

.qrcode
{
    display: flex;
    flex-direction: column;

    max-width: 300px;
    margin-right: auto;
    margin-left: auto;
    padding: 2em;

    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
}

.qrcode__visual
{
    max-width: 256px;
    max-height: 256px;
}

.qrcode__visual > *[style]
{
    width: 100% !important;
    height: 100% !important;
}

.qrcode__download
{
    font-size: 1.1em;
    font-weight: bold;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    margin-top: 2em;
    margin-right: auto;
    margin-left: auto;
    padding: .5em 1em;

    cursor: pointer;

    color: #282c34;
    border: 1px solid #282c34;
    border-radius: 3px;
    background-color: transparent;
}

.qrcode__download:focus,
.qrcode__download:hover
{
    color: #fff;
    background-color: #282c34;
}

.list
{
    margin: 0;
    padding: 0;

    list-style-type: none;
}

.list__item + .list__item
{
    margin-top: .5em;
}

.card
{
    display: flex;
    align-items: center;

    padding: 1em;

    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .1);

    gap: 1em;
}

.card__shortcode
{
    font-size: .875em;
    font-weight: bold;

    padding: .25em .75em;

    text-decoration: none;

    color: #fff;
    border-radius: 3px;
    background-color: #6b00e6;
}

.card__url
{
    font-size: .875em;
}

.card__clicks
{
    font-weight: bold;

    margin-left: auto;
}

@keyframes color-change
{
    0%
    {
        background-color: #282c34;
    }
    50%
    {
        background-color: #6b00e6;
    }
    75%
    {
        background-color: #e68600;
    }
    100%
    {
        background-color: #282c34;
    }
}
